<style lang="less" scoped>
.introduce {
  width: 100%;
  height: 100vh;
  .swiper-container {
    width: 100%;
    height: 100vh;
    &-first {
      width: 100%;
      height: 100%;
    }
  }
  &-home {
    width: 100%;
    height: 100%;
    background: rgba(15, 17, 24, 1);
    position: relative;
    &-first {
      width: 51.7%;
      height: 42.5%;
      font-size: 300px;
      font-weight: 500;
      color: rgba(27, 29, 40, 1);
      position: absolute;
      left: -5.3%;
      top: 14.7%;
    }
    &-second {
      width: 41.9%;
      height: 42.5%;
      font-size: 300px;
      font-weight: 500;
      color: rgba(27, 29, 40, 1);
      letter-spacing: 10px;
      position: absolute;
      text-align: right;
      right: -0.3%;
      top: 57.2%;
    }
    &-third {
      width: 37%;
      position: absolute;
      left: 51.3%;
      top: 22.3%;
      img {
        width: 100%;
      }
    }
    &-forth {
      width: 60.7%;
      height: 14.2%;
      font-size: 55px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 11.7%;
      top: 23.2%;
    }
    &-desc {
      width: 24.3%;
      // height: 14.2%;
      position: absolute;
      left: 11.7%;
      top: 37%;
      font-size: 14px;
      font-weight: 400;
      color: #dee6ff;
      line-height: 28px;
      text-align: justify;
    }
    &-desc1 {
      width: 24.3%;
      // height: 14.2%;
      position: absolute;
      left: 46.2%;
      top: 37%;
      font-size: 14px;
      font-weight: 400;
      color: #dee6ff;
      line-height: 28px;
      text-align: justify;
    }
    &-fifth {
      width: 140px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      text-align: center;
      line-height: 40px;
      border-radius: 29px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 11.7%;
      top: 57%;
    }
    &-seventh {
      width: 140px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      text-align: center;
      line-height: 40px;
      border-radius: 29px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 46.2%;
      top: 57%;
    }
    &-eighth {
      width: 140px;
      height: 40px;
      cursor: pointer;
      background: linear-gradient(
        211deg,
        rgba(248, 40, 108, 1) 0%,
        rgba(249, 98, 47, 1) 100%
      );
      text-align: center;
      line-height: 40px;
      border-radius: 29px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(255, 255, 255, 1);
      position: absolute;
      left: 33.7%;
      top: 46%;
    }
    &-news {
      padding-top: 120px;
    }
  }
  .introduce-home-sixth {
    position: absolute;
    left: 11.7%;
    // top: 88%;
    color: #fff;
    animation: scroll 3s linear infinite;
  }
  @keyframes scroll {
    from {
      top: 88%;
    }
    to {
      top: 94%;
      opacity: 0;
    }
  }
  &-main {
    width: 100%;
    height: 100%;
    background: #070911;
    position: relative;
    &-wrapper {
      width: 76.7%;
      max-width: 1104px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      &-first {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-first {
          width: 66.2%;
          position: relative;
          img {
            width: 100%;
          }
          &-absolute {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 37px;
            left: 40px;
            p:nth-child(1) {
              font-size: 18px;
              font-weight: 600;
              color: rgba(255, 255, 255, 1);
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 1px;
            }
            p:nth-child(3) {
              font-size: 9px;
              font-weight: 300;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 2px;
            }
            p:nth-child(4) {
              width: 20px;
              height: 2px;
              background: rgba(255, 255, 255, 1);
            }
          }
          :hover {
            .r-button {
              display: block;
            }
          }
        }
        &-second {
          width: 32.4%;
          position: relative;
          img {
            width: 100%;
          }
          &-absolute {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 37px;
            left: 40px;
            p:nth-child(1) {
              font-size: 18px;
              font-weight: 600;
              color: rgba(255, 255, 255, 1);
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 1px;
            }
            p:nth-child(3) {
              font-size: 9px;
              font-weight: 300;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 2px;
            }
            p:nth-child(4) {
              width: 20px;
              height: 2px;
              background: rgba(255, 255, 255, 1);
            }
          }
          :hover {
            .r-button {
              display: block;
            }
          }
        }
      }
      &-second {
        width: 100%;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &-first,
        &-second,
        &-third {
          width: 32.4%;
          position: relative;
          img {
            width: 100%;
          }
          &-absolute {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 37px;
            left: 40px;
            p:nth-child(1) {
              font-size: 18px;
              font-weight: 600;
              color: rgba(255, 255, 255, 1);
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 1px;
            }
            p:nth-child(3) {
              font-size: 9px;
              font-weight: 300;
              color: rgba(255, 255, 255, 1);
              letter-spacing: 2px;
            }
            p:nth-child(4) {
              width: 20px;
              height: 2px;
              background: rgba(255, 255, 255, 1);
            }
          }
          :hover {
            .r-button {
              display: block;
            }
          }
        }
      }
    }
  }
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    height: 24px;
    line-height: 24px;
    top: 92%;
  }
  /deep/
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
    margin: 0 18px;
    border: 2px solid rgba(255, 255, 255, 1);
  }
  /deep/
    .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets
    > .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0);
    border: 2px solid rgba(255, 255, 255, 1);
  }
  .r-button {
    width: 60px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    cursor: pointer;
    background: linear-gradient(
      211deg,
      rgba(248, 40, 108, 1) 0%,
      rgba(249, 98, 47, 1) 100%
    );
    border-radius: 21px;
    // font-weight: 600;
    color: rgba(255, 255, 255, 1);
    margin-top: 50px;
    display: none;
  }
}
</style>

<template>
  <div class="introduce">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="swiper-container-first">
            <div class="introduce-home">
              <div class="introduce-home-first">
                CREA
              </div>
              <div class="introduce-home-second">
                TIVE
              </div>
              <!-- <div class="introduce-home-third">
                    <img src="../assets/1pic_map.png"
                         alt="">
                  </div> -->
              <div class="introduce-home-forth">
                <!-- WELCOME TO <br />CECC -->
                <!-- 长三角创意经济合作<br />专业委员会 -->
                长三角创意经济合作专业委员会
              </div>
              <div class="introduce-home-desc">
                长三角创意经济合作专业委员会 (Creative Economy Cooperation Committee，简称CECC）是由长江三角洲城市经济协调会第十六届市长联席会议批准设立的区域性专业类政府间合作机构。
              </div>
              <div class="introduce-home-desc1">
                上海无畏创意经济区域合作促进中心，由十一届全国政协副主席、“中国创意经济之父”厉无畏教授积极推进，长三角创意经济合作专业委员会牵头发起。
              </div>

              <div class="introduce-home-fifth"
                   @click="$router.push({name: 'aboutUs'})">
                <!-- ABOUT US -->
                <!-- 关于我们 -->
                {{ $t('readMore') }}
              </div>
              <div class="introduce-home-sixth">
                <!-- Scroll Down -->
                下滑滚动
              </div>
              <div class="introduce-home-seventh"
                   @click="toFL">
                {{ $t('readMore') }}
              </div>
              <!-- 待更新 -->
              <!-- <div class="introduce-home-eighth"
                       @click="toCecc">
                    {{ $t('readMore') }}
                  </div> -->
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiper-container-first">
            <div class="introduce-home">
              <div class="introduce-home-news">
                <news />
              </div>

              <div class="introduce-home-sixth">
                <!-- Scroll Down -->
                下滑滚动
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="introduce-main">
            <div class="introduce-main-wrapper">
              <div class="introduce-main-wrapper-first">
                <div class="introduce-main-wrapper-first-first">
                  <img src="../assets/m1.png"
                       alt="">
                  <div class="introduce-main-wrapper-first-first-absolute">
                    <p>CECC</p>
                    <p>长三角创意经济委员会</p>
                    <p>CREATIVE ECONOMY COOPERATION COMMITTEE</p>
                    <p></p>
                    <div class="r-button"
                         @click="toCECC">
                      MORE
                    </div>
                  </div>
                </div>
                <div class="introduce-main-wrapper-first-second">
                  <img src="../assets/m2.png"
                       alt="">
                  <div class="introduce-main-wrapper-first-second-absolute">
                    <!-- <p>全球创意设计大赛2021</p> -->
                    <p>第二届全球创意设计大赛</p>
                    <p>POWER OF DESIGN</p>
                    <p>INTERNATIONAL DESIGN COMPETITION</p>
                    <p></p>
                    <div class="r-button"
                         @click="toIDC">
                      MORE
                    </div>
                  </div>
                </div>
              </div>
              <div class="introduce-main-wrapper-second">
                <div class="introduce-main-wrapper-second-second">
                  <img src="../assets/m4.png"
                       alt="">
                  <div class="introduce-main-wrapper-second-second-absolute">
                    <p>CWF</p>
                    <p>世界创意峰会</p>
                    <p>CREATIVITY WORLD FORUM</p>
                    <p></p>
                    <div class="r-button"
                         @click="toCWF">
                      MORE
                    </div>
                  </div>
                </div>
                <div class="introduce-main-wrapper-first-first">
                  <img src="../assets/m3_1.png"
                       alt="">
                  <div class="introduce-main-wrapper-second-first-absolute">
                    <p>无畏</p>
                    <p>创意经济区域合作促进中心</p>
                    <p>SHANGHAI WUWEI CENTRE</p>
                    <p></p>
                    <div class="r-button"
                         @click="toFL">
                      MORE
                    </div>
                  </div>
                </div>

                <!-- <div class="introduce-main-wrapper-second-third"
                     v-show="$Cookies.get('cecc-lang') !== 'en'">
                  <img src="../assets/m5.png"
                       alt="">
                  <div class="introduce-main-wrapper-second-third-absolute">
                    <p>锦绣江南名品榜</p>
                    <p>全球创意设计大赛·专属活动</p>
                    <p>JINXIU JIANGNAN</p>
                    <p></p>
                    <div class="r-button"
                         @click="toJiangnan">
                      MORE
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
            <Common-footer></Common-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
import news from '../components/news.vue';
import CommonFooter from '../components/common-footer';

export default {
  name: 'login',
  data () {
    return {
    }
  },
  mounted () {
    new Swiper('.swiper-container', {
      direction: 'vertical',
      mousewheel: true,
      keyboard: true
    });
    // new Swiper('.swiper-container-first', {
    //   autoplay: true,
    //   loop: true,
    //   pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    //   },
    // })
  },
  created () {
    if (this.$route.params.from === 'login') {
      this.$router.go(0);
    }
    if (this.$Cookies.get('cecc-lang') === 'en') this.$router.push({
      name: 'home_en'
    })
  },
  computed: {
  },
  methods: {
    toCECC () {
      this.$router.push({
        name: 'aboutUs'
      })
    },
    toIDC () {
      this.$router.push({
        name: 'IDC'
      })
    },
    toCWF () {
      this.$router.push({
        name: 'CWF'
      })
    },
    toJiangnan () {
      this.$router.push({
        name: 'fairviewJiangnan'
      })
    },
    toFL () {
      this.$router.push({
        name: 'culturalTour'
      })
    },
    toCeccDetail () {
      this.$router.push({
        name: 'aboutUs/details'
      })
    }
  },
  components: {
    CommonFooter,
    news
  },
}
</script>
